<template>
	<component :is="tag">
		<b-card-text v-html="materialObj.item_detail.description"></b-card-text>
		<!--not_completed-->
		<template v-if="courseCompletionStatus === 1">
			<!--material-->
			<template v-if="materialObj.is_material">
				<b-button
					@click="
						startResumeCourse(
							materialObj.item_detail.file_type,
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 1"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
					>Review Lesson
				</b-button>
				<b-button
					@click="
						startResumeCourse(
							materialObj.item_detail.file_type,
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 2"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
				>
					Take Lesson
				</b-button>
				<b-button
					@click="
						startResumeCourse(
							materialObj.item_detail.file_type,
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 3"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
				>
					Take Lesson
				</b-button>
			</template>

			<!--quiz-->
			<template v-if="!materialObj.is_material">
				<!--material-->
				<b-button
					@click="
						startResumeCourse(
							'quiz',
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 1"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
					>Review Quiz
				</b-button>
				<b-button
					@click="
						startResumeCourse(
							'quiz',
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 2"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
				>
					Continue to Quiz
				</b-button>
				<b-button
					@click="
						startResumeCourse(
							'quiz',
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 3"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
				>
					Take Quiz
				</b-button>
			</template>
		</template>

		<!--completed-->
		<template v-if="courseCompletionStatus === 2">
			<!--material-->
			<template v-if="materialObj.is_material">
				<b-button
					@click="
						onTakeLesson(
							materialObj.item_detail.file_type,
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 1"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
					>Review Lesson
				</b-button>
				<b-button
					@click="
						onTakeLesson(
							materialObj.item_detail.file_type,
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 2"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
				>
					Take Lesson
				</b-button>
				<b-button
					@click="
						onTakeLesson(
							materialObj.item_detail.file_type,
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 3"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
				>
					Take Lesson
				</b-button>
			</template>

			<!--quiz-->
			<template v-if="!materialObj.is_material">
				<b-button
					@click="
						onTakeLesson(
							'quiz',
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 1"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
					>Review Quiz
				</b-button>
				<b-button
					@click="
						onTakeLesson(
							'quiz',
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 2"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
				>
					Take Quiz
				</b-button>
				<b-button
					@click="
						onTakeLesson(
							'quiz',
							materialObj.item_detail,
							materialObj
						)
					"
					v-if="materialObj.course_progress_status_id === 3"
					:disabled="materialObj.course_progress_status_id === 3"
					:variant="variant"
				>
					Take Quiz
				</b-button>
			</template>
		</template>
	</component>
</template>

<script>
import {
	START_RESUME_COURSE,
	SET_CURRENT_MODULE_ID,
	SAVE_FINAL_CHECK_DATA,
} from "@/core/services/store/modules/trainee/course.module";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
	name: "CourseModuleBox",
	components: {},
	mixins: [],
	data() {
		return {
			variant: "info",
		};
	},
	props: {
		traineeCourseId: { required: true },
		courseCompletionStatus: { required: true },
		materialObj: { required: true },
		moduleId: { required: true },
		tag: {
			type: String,
			default: "div",
		},
	},
	mounted() {},
	methods: {
		startResumeCourse(type, materialDetail, material) {
			let payload = { trainee_course_id: this.traineeCourseId };
			this.$store.dispatch(START_RESUME_COURSE, payload).then((res) => {
				console.log("kucing");
				if (res.response_code === 2100) {
					console.log(res.data.output);

					this.$store.dispatch(SET_CURRENT_MODULE_ID, this.w);
					this.$store.dispatch(SAVE_FINAL_CHECK_DATA, {
						trainee_module_item_id: material.trainee_module_item_id,
						trainee_module_id: this.moduleId,
					});

					this.$router.push({
						name: "trainee-lesson",
						params: {
							traineeCourseId: this.$route.params.traineeCourseId,
							lessonType: type,
							lessonId: materialDetail.id,
							lessonObj: material,
							// moduleId: this.moduleId,
						},
					});
				} else {
					SystemHelper.errorHandler(res, null, "error");
				}
			});
		},
		onTakeLesson(type, materialDetail) {
			console.log("open lesson");
			this.$router.push({
				name: "trainee-lesson",
				params: {
					lessonType: type,
					lessonId: materialDetail.id,
					lessonObj: materialDetail,
				},
			});
		},
	},
	computed: {},
};
</script>
