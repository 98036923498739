/* eslint-disable */
<template>
	<div class="mt-1">
		<div>
			<b-jumbotron
				bg-variant="dark"
				text-variant="light"
				:style="
					`background-image: url(${backgroundImg}); background-size: cover; background-position: center`
				"
			>
				<div class="container">
					<b-row>
						<b-col xl="7" md="12">
							<h1 class="text-lg-h1 tx-dark-shadow">
								{{ CourseDetailsObj.course_name }}
							</h1>
							<p
								class="tx-dark-shadow"
								v-html="CourseDetailsObj.description"
							></p>
							<p
								class="tx-dark-shadow"
								v-html="CourseDetailsObj.long_description"
							></p>
							<p class="tx-dark-shadow">
								For more information visit website
							</p>
							<template
								v-if="
									CourseDetailsObj.completion_status_id === 1
								"
							>
								<b-button
									variant="info"
									v-if="!CourseStarted"
									@click="initCourse"
									>Start Course</b-button
								>
								<b-button
									variant="info"
									v-if="CourseStarted"
									@click="resumeCourse"
									>Continue Course</b-button
								>
							</template>
						</b-col>
						<b-col xl="1" md="12">
							<div style="height: 2rem"></div>
						</b-col>
						<b-col
							class="tx-dark-shadow text-center py-6"
							xl="4"
							md="12"
							v-if="CourseDetailsObj.completion_status_id === 2"
						>
							<b-img
								class="d-none d-md-block mx-auto"
								src="/assets/img/badges/completed_badge.svg"
								width="100px"
							></b-img>
							<h4>Congratulation!</h4>
							<h6>You have completed this course</h6>

							<b-button
								class="mt-5"
								variant="info"
								@click="downloadPledge"
								>Download Your Pledge</b-button
							>
						</b-col>
					</b-row>
				</div>
			</b-jumbotron>
			<div class="container">
				<b-row>
					<b-col xl="8" sm="12">
						<div class="timeline timeline-6 mt-3">
							<template v-for="(module, i) in CourseProgressObj">
								<!--begin::module-->
								<div
									class="timeline-item align-items-start"
									v-bind:key="i"
								>
									<!--begin::Label-->
									<span class="symbol symbol-35">
										<span
											data-v-8b11b090=""
											class="
                        symbol-label
                        font-size-h2 font-weight-bold
                        bg-transparent
                      "
										>
											{{ i + 1 }}
										</span>
									</span>
									<!--end::Label-->

									<!--begin::Text-->
									<div
										class="font-weight-normal timeline-content pl-8"
									>
										<span
											class="font-size-h2 font-weight-bolder"
											v-html="module.module_name"
										></span>
										<template>
											<div
												class="py-3"
												v-html="module.long_description"
											></div>
											<div
												class="accordion"
												v-bind:key="i"
												role="tablist"
											>
												<template
													v-for="(material,
													x) in module.trainee_module_items"
												>
													<b-card
														no-body
														class="mb-1"
														v-bind:key="x"
													>
														<pre class="d-none">{{
															material
														}}</pre>
														<b-card-header
															class="accordion-toggle"
															v-b-toggle="[
																`accordion-${i}-${x}`,
															]"
															header-tag="header"
															role="tab"
														>
															<div class="d-flex">
																<p
																	class="
                                    font-weight-bold
                                    flex-grow-1
                                    px-4
                                    p-3
                                    m-0
                                  "
																>
																	<template
																		v-if="
																			material.is_material
																		"
																		>[<span
																			class="text-uppercase"
																			>{{
																				material
																					.item_detail
																					.file_type
																			}}</span
																		>]
																		{{
																			material
																				.item_detail
																				.material_name
																		}}
																	</template>
																	<template
																		v-else
																	>
																		[<span
																			class="text-uppercase"
																			>Quiz</span
																		>]
																		{{
																			material
																				.item_detail
																				.quiz_name
																		}}
																	</template>
																</p>

																<span
																	class="p-3"
																	v-if="
																		material.course_progress_status_id ===
																			1
																	"
																>
																	<b-icon
																		variant="success"
																		icon="check-circle-fill"
																	></b-icon>
																</span>
																<span
																	class="p-3 chevron"
																>
																	<b-icon
																		icon="chevron-down"
																		rotate="0"
																	></b-icon>
																</span>
															</div>
														</b-card-header>
														<b-collapse
															:id="
																`accordion-${i}-${x}`
															"
															@shown="
																activeTableOfContents(
																	i
																)
															"
															accordion="my-accordion"
															:visible="
																material.course_progress_status_id ===
																	2
															"
															role="tabpanel"
														>
															<b-card-body>
																<course-module-box
																	:course-completion-status="
																		CourseDetailsObj.completion_status_id
																	"
																	:trainee-course-id="
																		$route
																			.params
																			.traineeCourseId
																	"
																	:material-obj="
																		material
																	"
																	:module-id="
																		module.trainee_module_id
																	"
																></course-module-box>
															</b-card-body>
														</b-collapse>
													</b-card>
												</template>
											</div>
										</template>
									</div>
									<!--end::Text-->
								</div>

								<!--end::module-->
							</template>
						</div>

						<div style="height: 10em"></div>
					</b-col>
					<b-col sm="12" class="d-none d-lg-block">
						<h6 class="ml-4 mt-3">Table Of Contents</h6>
						<div
							class="timeline table-of-contents timeline-6 ml-4 mt-3"
						>
							<template v-for="(item, i) in tableOfContents">
								<!--begin::Item-->
								<div
									class="timeline-item align-items-start"
									v-bind:key="i"
								>
									<!--begin::Badge-->
									<div class="timeline-badge">
										<i
											class="icon-xl"
											v-bind:class="item.badge"
										></i>
									</div>
									<!--end::Badge-->

									<!--begin::Text-->
									<div
										class="
                      font-weight-normal font-size-lg
                      timeline-content
                      text-muted
                      pl-3
                    "
									>
										<span
											v-bind:class="{
												'font-weight-bolder text-dark-75':
													item.bold,
												'mr-4': item.images,
											}"
											v-html="item.moduleName"
										></span>
									</div>
									<!--end::Text-->
								</div>
								<!--end::Item-->
							</template>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>

		<div class="d-none">
			<pre>CourseStarted {{ CourseStarted }}</pre>
			<pre>{{ CourseDetailsObj }}</pre>
			<pre>{{ CourseProgressObj }}</pre>
		</div>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
	DOWNLOAD_TRAINEE_CERTIFICATE,
	GET_VIEW_COURSE_PROGRESS,
	START_RESUME_COURSE,
	GET_PLEDGE,
	DOWNLOAD_PLEDGE,
} from "@/core/services/store/modules/trainee/course.module";
import SystemHelper from "@/core/services/systemhelper.service";
import UserService from "@/core/services/user.service";
import CourseModuleBox from "@/core/components/CourseModuleBox";

export default {
	components: { CourseModuleBox },
	name: "trainee-course",
	data() {
		return {
			pageParams: null,
			enrolled: false,
			progress: {
				modules: [
					{
						materials: [
							{ completed: true },
							{ completed: true },
							{ completed: true },
							{ completed: false },
						],
					},
					{
						materials: [{ completed: false }],
					},
				],
			},
			text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
		};
	},
	methods: {
		getModuleObjectbyId(moduleId) {
			console.log(moduleId);
			let myArray = this.CourseProgressObj;
			myArray.findIndex((x) => x.trainee_module_item_id === moduleId);

			console.log(myArray);
			return myArray;
		},
		initCourse() {
			let payload = {
				trainee_course_id: this.$route.params.traineeCourseId,
			};
			this.$store.dispatch(START_RESUME_COURSE, payload).then(() => {
				this.$store.dispatch(GET_VIEW_COURSE_PROGRESS, payload);
			});
		},
		resumeCourse() {
			console.log("resumeCourse");
			let payload = {
				trainee_course_id: this.$route.params.traineeCourseId,
			};
			this.$store.dispatch(START_RESUME_COURSE, payload).then((res) => {
				if (res.response_code === 2100) {
					if (res.response_code === 2100) {
						let data = res.data.output;
						console.log("data", data);

						let localArray = this.CourseProgressObj;

						let result = localArray.filter((module) => {
							return module.trainee_module_items.some(
								(trainee_module_item) => {
									return (
										trainee_module_item.trainee_module_item_id ===
										data.trainee_module_item_id
									);
								}
							);
						});

						console.log("result", result);

						let pushParams;
						if (data.material_id) {
							pushParams = {
								lessonType: data.material_detail.file_type,
								lessonId: data.material_detail.id,
								lessonObj: data.material_detail,
							};
						} else {
							pushParams = {
								lessonType: "quiz",
								lessonId: data.quiz_id,
							};
						}

						this.$router.push({
							name: "trainee-lesson",
							params: {
								traineeCourseId: this.$route.params
									.traineeCourseId,
								...pushParams,
							},
						});
					} else {
						SystemHelper.errorHandler(res, null, "error");
					}
				}
			});
		},
		downloadCertificate() {
			let payload = {
				trainee_course_id: this.$route.params.traineeCourseId,
				username: this.username,
			};
			this.$store.dispatch(DOWNLOAD_TRAINEE_CERTIFICATE, payload);
		},
		downloadPledge() {
			let payload = {
				pledge_id: this.PledgeData.pledge_id,
				course_id: this.$route.params.traineeCourseId,
				serialNo: this.PledgeData.serial_no,
			};
			this.$store.dispatch(DOWNLOAD_PLEDGE, payload);
		},
		activeTableOfContents(position) {
			return position;
		},
	},
	async mounted() {
		let payload = { trainee_course_id: this.$route.params.traineeCourseId };
		await this.$store.dispatch(GET_VIEW_COURSE_PROGRESS, payload);

		await this.$store.dispatch(SET_BREADCRUMB, [
			{ title: this.CourseDetailsObj.course_name },
		]);

		// get Pledge data for download purpose
		let pledgePayload = {
			course_id: this.$route.params.traineeCourseId,
		};
		await this.$store.dispatch(GET_PLEDGE, pledgePayload);

		this.activeTableOfContents(0); //set/highlight default table of contents item
	},
	computed: {
		username() {
			let userData = JSON.parse(UserService.getUserData());
			return userData.username;
		},
		backgroundImg() {
			let x = this.CourseDetailsObj;
			if (x) {
				return x.course_image_link;
			} else {
				return "/assets/img/placeholders/sample_bg02.jpg";
			}
		},
		CourseStarted() {
			let course = this.CourseProgressObj;

			let x = course.some((obj) => {
				return obj.trainee_module_items.some((traineeModObj) => {
					return traineeModObj.course_progress_status === "ON_GOING";
				});
			});
			return x;
		},
		CourseDetailsObj() {
			const course = this.$store.state.trainee_course
				.courseProgressDetail;

			if (course) {
				return course.course_detail;
			} else {
				return {};
			}
		},
		CourseProgressObj() {
			const course = this.$store.state.trainee_course
				.courseProgressDetail;

			if (course) {
				return course.structure;
			} else {
				return [];
			}
		},
		PledgeData() {
			const pledgeData = this.$store.state.trainee_course.pledgeData;
			if (
				typeof pledgeData === "object" &&
				Object.keys(pledgeData).length
			) {
				return pledgeData;
			} else {
				return {};
			}
		},
		tableOfContents() {
			let courseModules = this.CourseProgressObj;
			let tableOfContentItems = [];

			if (Array.isArray(courseModules) && courseModules.length) {
				courseModules.forEach((module) => {
					tableOfContentItems.push({
						moduleName: module.module_name,
						badge: module.active
							? "fa fa-genderless text-info "
							: "fa fa-genderless ",
						bold: module.active,
					});
				});
				return tableOfContentItems;
			} else {
				return [];
			}
		},
	},
};
</script>
